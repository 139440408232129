<template>
  <div>
    <v-card-subtitle class="mt-6 pb-2">Vous ne trouvez pas votre exploitation ?</v-card-subtitle>
    <v-dialog
        width="600"
        v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col>
            <v-btn
                id="btn-ex-add"
                small
                color="info"
                v-bind="attrs"
                v-on="on"
            >Ajouter une exploitation</v-btn>
          </v-col>
        </v-row>
      </template>

      <v-card
        :loading="loading"
      >
        <v-card-title
          class="primary"
        >
          Ajouter une exploitation
        </v-card-title>
        <v-card-text class="mt-8">
          <v-form
            @submit="formSubmit"
            ref="form_addexploitation"
            v-model="valid"
            :disabled="loading"
          >
            <v-row>
              <v-col
                  md="6"
                  cols="12"
              >
                <v-text-field
                    v-model.trim="code"
                    type="text"
                    label="Code adhérent"
                    placeholder="Votre code adhérent"
                    class="required"
                    :rules="[rules.required, rules.code]"
                    required
                ></v-text-field>
              </v-col>

              <v-col
                  md="6"
                  cols="12"
              >
                <v-text-field
                    v-model="name"
                    type="text"
                    label="Raison sociale"
                    placeholder="EARL EXEMPLE"
                    class="required"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-col>

              <v-col
                  md="6"
                  cols="12"
              >
                <v-text-field
                    v-model.trim="siren"
                    type="number"
                    label="N° Siren"
                    placeholder="850106832"
                    class="required cls-siren"
                    :rules="[rules.required, rules.siren]"
                    counter="9"
                    min="0"
                    required
                    @keydown="key_codes.includes($event.keyCode) ? $event.preventDefault() : false"
                ></v-text-field>
              </v-col>

              <v-col
                  md="4"
                  sm="6"
                  cols="12"
              >
                <v-text-field
                    v-model.trim="zip_code"
                    type="text"
                    label="Code postal"
                    placeholder="42000"
                    class="required"
                    :rules="[rules.required, rules.zip_code]"
                    counter="5"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="btn-ex-send"
            color="error"
            small
            @click="dialog = false"
            v-if="!loading"
          >
            Annuler
          </v-btn>
          <v-btn
            id="btn-ex-cancel"
            color="success"
            small
            @click="formSubmit"
            :disabled="!valid || loading"
            :loading="loading"
          >
            Envoyer ma demande d'ajout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="message.show"
        :color="message.color"
        :timeout="message.timeout"
        top
    >
      <span v-html="message.text"></span>
    </v-snackbar>
  </div>
</template>

<script>
import exploitationsService from "@/services/exploitationsService";
import {mapGetters} from "vuex";

export default {
  name: "AjoutExploitation",
  data() {
    return {
      message: {
        show: false,
        text: '',
        color: '',
        timeout: -1,
      },
      dialog: false,
      valid: false,
      loading: false,
      code: '',
      name: '',
      siren: '',
      zip_code: '',
      key_codes: [13, 107, 109, 110, 187, 188, 190],
      rules: {
        required: value => !!value || 'Veuillez remplir ce champs.',
        siren: value => value.match(/^[0-9]{9}$/g) !== null || 'Un siren est composé de 9 chiffres.',
        code: value => !this.exploitationExists(value) || 'Cette exploitation est déjà enregistrée sur votre compte.',
        zip_code: value => value.match(/^[0-9]{5}$/g) !== null || 'Un code postal est composé de 5 chiffres.',
      },
    };
  },
  methods: {
    formSubmit() {
      if (this.$refs.form_addexploitation.validate()) {
        this.loading = true;
        exploitationsService.addExploitation({
          code_tiers: this.code,
          raison_sociale: this.name,
          siren: this.siren,
          code_postal: this.zip_code,
        }).then(() => {
          this.loading = false;
          this.dialog = false;

          this.message = {
            show: true,
            text:
                'Votre demande a bien été envoyée.<br />' +
                'La nouvelle exploitation sera disponible d\'ici quelques minutes.<br />' +
                'En attendant, merci de sélectionner une exploitation et de cliquer sur continuer.',
            color: 'success',
            timeout: 10000,
          }
        }).catch(err => {
          console.log(err);

          this.loading = false;
          this.message = {
            show: true,
            text: 'Une erreur est survenue...',
            color: 'error',
            timeout: 3000,
          }
        });
      }
    },
    exploitationExists(code) {
      code += '-450';

      let token = this.getToken;
      let payload = token.split('.')[1];
      let data = JSON.parse(Buffer.from(payload, 'base64').toString());
      let groups = this.parseGroups(data.groups);

      for (const group of groups) {
        if (group.exploitation === code) {
          return true;
        }
      }

      return false;
    },
    parseGroups(groups) {
      let parsed = [];

      groups.map(group => {
        let split = group.split('/');
        parsed.push({
          exploitation: split[1],
          type: split[2],
          application: split[3],
        });
      });

      return parsed;
    }
  },
  watch: {
    dialog() {
      this.code = '';
      this.name = '';
      this.siren = '';
      this.zip_code = '';
      this.valid = false;
      if (this.$refs.form_addexploitation !== undefined) {
        this.$refs.form_addexploitation.resetValidation();
      }
    },
    // siren(newVal, oldVal) {
      
    // }
  },
  computed: {
    ...mapGetters(['getToken']),
  },
}
</script>

<style scoped>
  .required label::after {
    content: " *";
    color: yellowgreen;
  }
  

  .cls-siren >>> input[type="number"] {
    -moz-appearance: textfield;
  }
  .cls-siren >>> input::-webkit-outer-spin-button,
  .cls-siren >>> input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
</style>
