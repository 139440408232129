<template>
  <v-app>
    <v-main>
      <v-container class="text-center mt-0 mt-sm-6 mt-md-12">
        <v-img
            class="d-inline-block"
            :src="require('./assets/moneureden.png')"
            max-width="80%"
            width="300px"
        />

        <v-row
          justify="center"
          class="mt-12"
        >
          <v-col
            lg="6"
            md=""
            sm=""
            xs=""
          >
            <v-card>
              <v-card-title>Changer d'exploitation</v-card-title>
              <v-card-text>
                <ListeExploitation />
                <AjoutExploitation />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AjoutExploitation from "@/components/AjoutExploitation";
import ListeExploitation from "@/components/ListeExploitation";

export default {
  name: 'App',

  components: {
    ListeExploitation,
    AjoutExploitation,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
  #app {
      background-image: linear-gradient(rgba(225,225,225,0.65), rgba(225,225,225,0.65)), url("assets/background.jpg");
      background-position: center center, center center;
      background-repeat: no-repeat, no-repeat;
      background-attachment: fixed, fixed;
      background-size: cover, cover;
  }
</style>