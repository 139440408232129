import config from '@/config';
import axios from "axios";
import store from '@/store';

const getConfig = () => {
    return {
        headers: {
            Authorization: store.state.keycloak && store.state.keycloak.accessToken ? store.state.keycloak.accessToken : '',
        }
    };
};

const get = (url) => {
    return axios.get(config.backend + '/' + url, getConfig());
};

const post = (url, data) => {
    return axios.post(config.backend + '/' + url, data, getConfig());
};

export default {
    get,
    post,
};
