import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#e2e362',
                secondary: '#000000',
                edit: '#1867c0',
                accent: '#2196F3',
                error: '#d53d18',
                info: '#2196F3',
                success: '#7ea71d',
                warning: '#FFC107',
            }
        },
        options: {
            customProperties: true,
            variations: false
        },
    }
});
