import axios from "axios";

const config = {
    backend: '',
    auth: {
        url: '',
        realm: '',
        clientId: '',
    },

    promise: axios.get('env.json').then(response => {
        for (const key in response.data) {
            config[key] = response.data[key];
        }
    }),
};

export default config;
