<template>
  <div>
    <v-row>
      <v-col>
        <v-select
          id="select-ex"
          :items="exploitations.length > 0 ? listeExploitations : []"
          :placeholder="loading !== 0 ? 'Chargement...' : 'Choisissez une exploitation'"
          item-text="nom"
          item-value="code"
          v-model="exploitation"
          :loading="loading !== 0"
        >
          <template v-slot:no-data v-if="exploitations.length <= 0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Aucune exploitation disponible...</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col
        lg="4"
        md="4"
        sm="3"
        xs="12"
        cols="12"
      >
        <v-btn
          id="btn-continue"
          color="success"
          :disabled="exploitation === null"
          @click="redirect"
        >Continuer</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from "@/config";
import exploitationsService from "@/services/exploitationsService";
import applicationsService from "@/services/applicationsService";
import { mapGetters } from 'vuex';

export default {
  name: "ListeExploitation",
  data: () => ({
    /**
     * @member {{
     *   code: string,
     *   dct: string,
     *   nom: string,
     * }[]}
     */
    exploitations: [],

    /**
     * @member {{
     *   id: number,
     *   code_application: string,
     *   description: string,
     *   libelle: string,
     *   role: string,
     *   url: string,
     * }[]}
     */
    applications: [],

    /**
     * @member {?string}
     */
    exploitation: null,

    /**
     * @member {number}
     */
    loading: 2,
  }),
  methods: {
    /**
     * Redirige l'utilisateur sur l'application d'origine avec l'exploitation choisie
     */
    redirect() {
      window.location = `${this.query.redirect_url}?exploitation=${this.exploitation}`
    },

    /**
     * Vérifie sur une exploitation est disponible pour l'application choisie
     *
     * @param exploitation {{
     *   code: String,
     *   nom: String,
     * }}
     * @return boolean
     */
    isAvailable(exploitation) {
      let app_role = this.getApplicationRole();

      if (app_role === null) {
        return false;
      }

      return this.hasAccess(exploitation.code, app_role);
    },

    /**
     * Vérifie que l'utilisateur a accès à un code tiers pour le role applicatif donné dans son token
     *
     * @param code {string}
     * @param role {string}
     * @return {boolean}
     */
    hasAccess(code, role) {
      if (this.getAccessToken() === undefined)
        return false;

      let token = this.getAccessToken();
      let payload = token.split('.')[1];
      let data = JSON.parse(Buffer.from(payload, 'base64').toString());
      let groups = this.parseGroups(data.groups);

      for (const group of groups) {
        if (group.exploitation === code && group.application === role)
          return true;
      }

      return false;
    },

    /**
     * Récupère le role (groupe) de l'application choisie
     *
     * @return {?string}
     */
    getApplicationRole() {
      if ('group' in this.query) {
        return this.query.group;
      }

      let url = ('redirect_url' in this.query) ? this.getDomain(this.query.redirect_url) : '';

      for (const application of this.applications) {
        if (url === this.getDomain(application.url)) {
          return application.role;
        }
      }

      return null;
    },

    /**
     * Récupère le nom d'hôte pour une URL
     *
     * @param url {string}
     * @return {string}
     */
    getDomain(url) {
      let hostname;

      if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
      } else {
        hostname = url.split('/')[0];
      }

      hostname = hostname.split(':')[0];
      hostname = hostname.split('?')[0];

      return hostname;
    },

    /**
     * Parse les "groups" du token
     *
     * @param groups {string[]}
     * @return {{
     *   exploitation: {string},
     *   type: {string},
     *   application: {string},
     * }[]}
     */
    parseGroups(groups) {
      let parsed = [];

      groups.map(group => {
        let split = group.split('/');
        parsed.push({
          exploitation: split[1],
          type: split[2],
          application: split[3],
        });
      });

      return parsed;
    },

    /**
     * Récupère l'accessToken de l'utilisateur
     * @return {string}
     */
    getAccessToken() {
      return this.$store.state.keycloak.accessToken;
    },
  },
  created() {
    config.promise.then(() => {
      exploitationsService.getExploitations().then(exploitations => {
        this.exploitations = exploitations.exploitations;
        this.loading--;
      });

      if ('group' in this.query) {
        this.loading--;
      } else {
        applicationsService.getApplications().then(applications => {
          this.applications = applications.applications;
          this.loading--;
        });
      }
    })
  },
  computed: {
    ...mapGetters([
        'query',
    ]),

    listeExploitations() {
      if (this.getAccessToken() === undefined)
        return [];

      let dispo = [];
      let indispo = [];
      let data = [];

      /**
       * Tri les exploitations dispo et indispo pour l'application choisie
       *
       * @param exploitation {{
       *   code: String,
       *   dct: String,
       *   nom: String,
       * }}
       */
      this.exploitations.map(exploitation => {
        if (exploitation.dct) {
          exploitation.nom += ` (${exploitation.code})`;
          exploitation.code += `-${exploitation.dct}`;
          delete exploitation.dct;
        }

        if (this.isAvailable(exploitation)) {
          exploitation.disabled = false;
          dispo.push(exploitation);
        } else {
          exploitation.disabled = true;
          indispo.push(exploitation);
        }
      });

      data.push({ header:'Exploitations disponibles' });
      data = data.concat(dispo);

      data.push({ divider: true });
      data.push({ header:'Exploitations indisponibles' });
      data = data.concat(indispo);

      return data;
    }
  }
}
</script>
