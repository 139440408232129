import service from "@/services/service";

const getExploitations = async () => {
    return (await service.get('exploitations')).data;
}

const addExploitation = async (data) => {
    return (await service.post('exploitations', data)).data;
}

export default {
    getExploitations,
    addExploitation,
};
