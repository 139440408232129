import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Keycloak from "keycloak-js";
import config from "@/config";
import store from "@/store";

Vue.config.productionTip = false;

config.promise.then(() => {
  let initOptions = {
    url: config.auth.url,
    realm: config.auth.realm,
    clientId: config.auth.clientId,
    onLoad: 'login-required'
  }

  let keycloak = Keycloak(initOptions);
  keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      new Vue({
        store,
        vuetify,
        render: h => h(App, { props: { keycloak: keycloak } })
      }).$mount('#app');

      let payload = {
        idToken: keycloak.idToken,
        accessToken: keycloak.token
      }
      store.commit('login', payload);
    }

    //Token Refresh
    setInterval(() => {
      keycloak.updateToken(70).then(() => {
        let payload = {
          idToken: keycloak.idToken,
          accessToken: keycloak.token
        }
        store.commit('login', payload);
      });
    }, 4000);
  });
})