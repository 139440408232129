import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        keycloak: {},
    },
    mutations: {
        login (state, keycloak) {
            state.keycloak = keycloak;
        },
    },
    getters: {
        query() {
            let params = window.location.search.substring(1).split('&');
            let data = [];

            for (const param of params) {
                let i = param.indexOf('=');
                let key = param.substring(0, i);
                data[key] = param.substring(i + 1);
            }

            return data;
        },
        getToken(state) {
            return state.keycloak.accessToken;
        },
    },
});

export default store;
